<template>
<svg width="216" height="130" viewBox="0 0 216 130" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.70068 55.2578V126.635C3.70068 128.486 5.21505 130 7.06594 130H86.1494C92.3078 130 97.2884 124.986 97.2884 118.861V106.039H100.317C102.168 106.039 103.682 104.525 103.682 102.674V80.5308C103.682 78.6799 102.168 77.1656 100.317 77.1656H97.2884V63.0315C97.2884 56.8731 92.2741 51.8925 86.1494 51.8925H85.409V42.8063C85.409 39.0372 83.6591 35.6047 80.6304 33.3836C77.6016 31.1625 73.7989 30.4895 70.1981 31.6H70.1644C70.1644 31.6 21.7384 47.0802 7.09959 51.7915C5.38331 52.3636 3.70068 53.4069 3.70068 55.2578ZM78.6785 51.9262H28.6372L72.2172 38.0277C72.722 37.8931 73.1932 37.7921 73.6979 37.7921C74.7412 37.7921 75.7844 38.1286 76.6594 38.768C77.9718 39.7103 78.7122 41.191 78.7122 42.8063V51.9262H78.6785ZM78.1737 99.3089C73.9335 99.3089 70.4673 95.8427 70.4673 91.6025C70.4673 87.3623 73.9335 83.8961 78.1737 83.8961H96.9518V99.3089H78.1737ZM10.4312 58.623H86.1494C88.606 58.623 90.5915 60.6085 90.5915 63.0651V77.1992H78.1737C70.2317 77.1992 63.7368 83.6605 63.7368 91.6361C63.7368 99.6118 70.1981 106.073 78.1737 106.073H90.5915V118.895C90.5915 121.351 88.606 123.337 86.1494 123.337H10.4312V58.623Z" fill="currentColor"/>
  <path d="M77.669 94.9677C78.544 94.9677 79.4189 94.5975 80.0247 93.9917C80.6641 93.3523 81.0006 92.511 81.0006 91.636C81.0006 90.7611 80.6304 89.8861 80.0247 89.2804C79.3853 88.641 78.544 88.3044 77.669 88.3044C76.794 88.3044 75.9191 88.6746 75.3133 89.2804C74.6739 89.9198 74.3374 90.7611 74.3374 91.636C74.3374 92.511 74.7076 93.386 75.3133 93.9917C75.9191 94.5975 76.794 94.9677 77.669 94.9677Z" fill="currentColor"/>
  <path d="M14.8718 30.927C13.9968 30.927 13.0882 30.6241 12.3479 30.052L1.51175 21.336C-0.238185 19.9226 -0.507405 17.3986 0.906001 15.6487C2.28575 13.8988 4.84335 13.6296 6.59328 15.043L17.4294 23.759C19.1793 25.1724 19.4485 27.6963 18.0351 29.4462C17.2275 30.4222 16.0496 30.927 14.8718 30.927Z" fill="currentColor"/>
  <path d="M92.5065 30.9269C91.3286 30.9269 90.1508 30.4221 89.3431 29.4125C87.9297 27.6626 88.2326 25.1387 89.9489 23.7253L100.785 15.0093C102.535 13.5959 105.059 13.8987 106.472 15.615C107.886 17.3649 107.583 19.8889 105.867 21.3023L95.0304 30.0183C94.29 30.624 93.4151 30.9269 92.5065 30.9269Z" fill="currentColor"/>
  <path d="M50.9475 20.4944C48.7264 20.4944 46.9092 18.6772 46.9092 16.4561V4.0383C46.9092 1.81724 48.7264 0 50.9475 0C53.1686 0 54.9858 1.81724 54.9858 4.0383V16.4561C54.9858 18.7108 53.1686 20.4944 50.9475 20.4944Z" fill="currentColor"/>
  <path d="M129.664 82.144C134.344 75.0027 138.955 67.9827 143.496 61.084H150.204V82.144H155.612V87.604H150.204V94H143.444V87.604H129.664V82.144ZM135.54 82.144H143.444V69.82L135.54 82.144ZM171.861 60.46C175.882 60.46 179.106 62.384 181.533 66.232C183.578 69.4213 184.601 73.2173 184.601 77.62C184.601 82.924 183.301 87.1707 180.701 90.36C178.413 93.2027 175.466 94.624 171.861 94.624C167.735 94.624 164.511 92.7867 162.189 89.112C160.247 86.0613 159.277 82.2307 159.277 77.62C159.277 72.4893 160.559 68.2773 163.125 64.984C165.447 61.968 168.359 60.46 171.861 60.46ZM171.965 66.284C168.221 66.284 166.349 69.9587 166.349 77.308C166.349 81.0173 166.886 83.9293 167.961 86.044C168.931 87.9507 170.214 88.904 171.809 88.904C173.577 88.904 174.963 87.8813 175.969 85.836C177.009 83.756 177.529 80.9133 177.529 77.308C177.529 69.9587 175.674 66.284 171.965 66.284ZM188.266 82.144C192.946 75.0027 197.556 67.9827 202.098 61.084H208.806V82.144H214.214V87.604H208.806V94H202.046V87.604H188.266V82.144ZM194.142 82.144H202.046V69.82L194.142 82.144Z" fill="currentColor"/>
</svg>
</template>
